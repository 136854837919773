import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function ViewRequestForm({ requestId }) {
  const [client, setClient] = useState(null);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const navigate = useNavigate();
  const inputRefs = {
    vehicleType: useRef(null),
    name: useRef(null),
    nric: useRef(null),
    plateNo: useRef(null),
  };

  useEffect(() => {
    // Define the API endpoint URL with the ID parameter
    const apiUrl = `https://api.kasirenew.com/api/clients/${requestId}`; // Replace with your API URL

    // Make a GET request to fetch data for the specified ID
    axios
      .get(apiUrl)
      .then((response) => {
        // Handle successful response and update the state with the client data
        setClient(response.data);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error('An error occurred while fetching data:', error);
      });
  }, [requestId]); // The 'id' parameter is added to the dependency array

  const handleButtonClick = (id) => {
    setSelectedRequestId(id);
    navigate(`/request/${id}/${client.plate_no}/submitquotation`);
  };
  

  const handleCopyClick = (field) => {
    if (inputRefs[field].current) {
      inputRefs[field].current.select();
      document.execCommand('copy');
    }
  };

  return (
    <div>
      {client ? (
        <div className="py-4">
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Vehicle Type:</label>
            <div className="flex items-center">
              <input
                ref={inputRefs.vehicleType}
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Vehicle Type"
                value={client.vehicle_type}
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover:bg-purple-500"
                onClick={() => handleCopyClick('vehicleType')}
              >
                Copy
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Name:</label>
            <div className="flex items-center">
              <input
                ref={inputRefs.name}
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Name"
                value={client.nama}
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover:bg-purple-500"
                onClick={() => handleCopyClick('name')}
              >
                Copy
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">NRIC:</label>
            <div className="flex items-center">
              <input
                ref={inputRefs.nric}
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter NRIC"
                value={client.id_number}
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover:bg-purple-500"
                onClick={() => handleCopyClick('nric')}
              >
                Copy
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Plate No:</label>
            <div className="flex items-center">
              <input
                ref={inputRefs.plateNo}
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Plate No"
                value={client.plate_no}
                readOnly
              />
              <button
                className="bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover:bg-purple-500"
                onClick={() => handleCopyClick('plateNo')}
              >
                Copy
              </button>
            </div>
          </div>

          <button
            className="bg-purple-600 text-white rounded-lg py-2 px-4 hover:bg-purple-500 mt-4"
            onClick={() => handleButtonClick(client.id)}
          >
            View Quotation Form
          </button>
        </div>
      ) : (
        <div className="flex justify-center items-center mt-20">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-700"></div>
        </div>
      )}
    </div>
  );
}

export default ViewRequestForm;
