import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import axios from 'axios';
import StickyNavbar from '../components/StickyNavbar';

function SubAgen() {
    const [allSubagen, setAllSubagens] = useState([]); // Store all clients
    const [subagens, setSubagens] = useState([]); // Filtered clients
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Make a GET request to fetch data from the API
        axios.get('https://api.kasirenew.com/api/subagens')
            .then((response) => {
                // Handle successful response and update the state
                setAllSubagens(response.data);
                console.log(response.data);
                setIsLoading(false);
            })
            .catch((error) => {
                // Handle errors if the request fails
                console.error('An error occurred while fetching data:', error);
                setIsLoading(false);
            });
    }, []);

    // Filter clients based on search text
    useEffect(() => {
        const filteredSubagens = allSubagen.filter((subagen) =>
            subagen.name.toLowerCase().includes(searchText.toLowerCase()) ||
            subagen.username.toLowerCase().includes(searchText.toLowerCase())
        );
        setSubagens(filteredSubagens);
    }, [searchText, allSubagen]);

    const handleListItemClick = (id) => {
        setSelectedRequestId(id);
        navigate(`/subagen/${id}`);
    };

    const handleSearchInputChange = (e) => {
        setSearchText(e.target.value);
    };

    return (
        <div className="flex bg-gray-100 min-h-screen mb-14 md:mb-0">
            <Sidebar />
            <div className="flex-grow px-8 py-5">
                <Header />
                <div className='flex justify-between'>
                    <h1 className="text-2xl font-semibold">SubAgen</h1>
                </div>
                <p className="text-gray-600">Welcome to the SubAgen page!</p>
                
                {/* Search Form */}
                <div className="mt-4">
                    <input
                        type="text"
                        placeholder="Search Name or Username"
                        className="border border-gray-300 rounded-lg p-2 w-full"
                        value={searchText}
                        onChange={handleSearchInputChange}
                    />
                </div>

                {isLoading ? (
                    <div className="flex justify-center items-center mt-20">
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-700"></div>
                    </div>
                ) : (
                    <ul className="mt-6">
                        {subagens.map((item) => (
                            <li
                                key={item.id}
                                className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-50 mb-4 cursor-pointer"
                                onClick={() => handleListItemClick(item.id)}
                            >
                                <div className="flex items-center justify-between">
                                    <span className="font-semibold text-lg">{item.name}</span>
                                    <span className="text-gray-600">{item.username}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
          <StickyNavbar />
        </div>
    );
}

export default SubAgen;
