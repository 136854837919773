import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import StickyNavbar from '../../components/StickyNavbar';
import ConfirmationDialog from '../../modals/ConfirmationDIalog';

function SubAgenInfo() {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({});
  const [loading, setLoading] = useState(true); // Initialize isCopied as an array
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`https://api.kasirenew.com/api/subagen/${id}`)
      .then((response) => {
        setDetailsData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [id]);

  // const handleConfirm = () => {
  //   closeModal();
  //   setLoading(true);
  //   axios
  //     .put(`https://api.kasirenew.com/api/subagen/${user}/${payout}`)
  //     .then((response) => {
  //       setLoading(false);
  //       // Handle the response here, if needed
  //       console.log(response.data); // Log the response data
  //       navigate('/pay');
  //     })
  //     .catch((error) => {
  //       // Handle errors, if any
  //       console.error('Error:', error);
  //     });
  // };

  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar />

      <div className="flex-grow px-3 md:px-8 py-5 mb-12 md:mb-0">
        <Header backTo="/subagen" />
        <h1 className="text-3xl font-semibold mb-4">View SubAgen Info</h1>
        <p className="text-gray-600 mb-8">Here's the details of your subagen</p>

        {loading ? (
          <div className="mt-12 flex items-center justify-center">
            <FaSpinner className="text-4xl text-purple-600 animate-spin" />
          </div>
        ) : (
          <div className="py-4">
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Name:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.name}
                  readOnly
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Username:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.username}
                  readOnly
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Email:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.email}
                  readOnly
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Commission Rate (%):</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.subagen_rate}
                  readOnly
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Bank:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.bank}
                  readOnly
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Account No:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.account}
                  readOnly
                />
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Account Owner:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={detailsData.accountowner}
                  readOnly
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <StickyNavbar />
    </div>
  );
}

export default SubAgenInfo;
