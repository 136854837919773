
import React from 'react';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import SubmitNewForm from '../../components/SubmitNewForm';

function SubmitNew() {
  return (
    <div>
      {/* Use the Header component */}

      <div className="flex bg-gray-100">
        <Sidebar />
        <div className="flex-grow px-8 py-5">
            <Header backTo={"/request"}/>
            <h1 className="text-2xl font-semibold">Submit Quotation</h1>
            <p className="text-gray-600">Fill out the quotation form:</p>
            <SubmitNewForm/>
        </div>
      </div>
    </div>
  );
}

export default SubmitNew;
