import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the id

import ViewSubmittedForm from '../../components/ViewSubmittedForm';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';

function ViewSubmitted() {
    const { id, plate_no, redirect } = useParams();
  
  return (
    <div>
      <div className="flex bg-gray-100">
        <Sidebar />
        <div className="flex-grow px-8 py-5">
            <Header backTo={`/${redirect}`}/>
            <h1 className="text-2xl font-semibold">View Submitted</h1>
            <p className="text-gray-600">Update any value:</p>
            <ViewSubmittedForm requestId={id} plateNo={plate_no} redirect={redirect} />
        </div>
      </div>
    </div>
  );
}

export default ViewSubmitted;
