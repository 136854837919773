import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import StickyNavbar from '../../components/StickyNavbar';

function ViewInvoice() {
  const [invoiceData, setInvoiceData] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state
  const { id } = useParams();

  useEffect(() => {
    // Fetch data from the API using Axios
    axios
      .get(`https://api.kasirenew.com/api/view-invoice/${id}`)
      .then((response) => {
        setInvoiceData(response.data);
        setLoading(false); // Set loading to false when data is fetched
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false on error
      });
  }, [id]);

  const formatPaidAt = (paidAt) => {
    const utcDate = new Date(paidAt + ' UTC');
    utcDate.setHours(utcDate.getHours() + 8);
    const dd = String(utcDate.getUTCDate()).padStart(2, '0');
    const mm = String(utcDate.getUTCMonth() + 1).padStart(2, '0');
    const yyyy = utcDate.getUTCFullYear();
    const hh = String(utcDate.getUTCHours()).padStart(2, '0');
    const min = String(utcDate.getUTCMinutes()).padStart(2, '0');
    const ss = String(utcDate.getUTCSeconds()).padStart(2, '0');
    return `${dd}/${mm}/${yyyy} ${hh}:${min}:${ss}`;
  };

  const paidAt = formatPaidAt(invoiceData.payments?.[0]?.paid_at);

  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar />

      <div className="flex-grow px-3 md:px-8 py-5">
        <Header backTo="/orders" />
        <h1 className="text-3xl font-semibold mb-4">View Invoice</h1>
        <p className="text-gray-600 mb-8">Here's the invoice of your client</p>

        {loading ? ( 
          <div className="mt-12 flex items-center justify-center">
            <FaSpinner className="text-4xl text-purple-600 animate-spin" />
          </div>
        ) : (
          <div className="bg-white p-4 rounded-md shadow-md">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <p className="text-lg font-semibold mb-2">Client Information</p>
                <p>
                  <strong>Name:</strong> {invoiceData.client?.nama}
                </p>
                <p>
                  <strong>Email:</strong> {invoiceData.client?.email}
                </p>
                <p>
                  <strong>WhatsApp Number:</strong> {invoiceData.client?.whatsapp_no}
                </p>
                <p>
                  <strong>Plate Number:</strong> {invoiceData.client?.plate_no}
                </p>
                <p>
                  <strong>Provider:</strong> {invoiceData.quotations?.[0]?.takaful_options}
                </p>
                <p>
                  <strong>Sum Cover:</strong> RM{invoiceData.quotations?.[0]?.sum_cover}
                </p>
                <p>
                  <strong>Price:</strong> RM{invoiceData.quotations?.[0]?.price}
                </p>
                <p>
                  <strong>Status:</strong> {invoiceData.client?.status}
                </p>
              </div>
              <div>
                <p className="text-lg font-semibold mb-2">Payment Information</p>
                <p>
                  <strong>Payment ID:</strong> {invoiceData.payments?.[0]?.payment_id}
                </p>
                <p>
                  <strong>Paid At:</strong> {paidAt}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>

      <StickyNavbar />
    </div>
  );
}

export default ViewInvoice;
