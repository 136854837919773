import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import { FaCheckCircle } from 'react-icons/fa'; // Import the check circle icon

function SuccessSubmit() {
  const { id } = useParams();
  const [clientData, setClientData] = useState({});
  
  useEffect(() => {
    // Fetch data from the API
    axios.get(`https://api.kasirenew.com/api/clients/${id}`)
      .then(response => {
        setClientData(response.data);
      })
      .catch(error => {
        console.error('Error fetching client data: ', error);
      });
  }, [id]);

  // Create the WhatsApp link with the fetched data
  const whatsappLink = `https://wa.me/6${clientData.whatsapp_no}?text=Quotation%20insuran%20kenderaan%20Tn%2FPn%20telah%20tersedia.%20Klik%20pautan%20berikut%3A%20https%3A%2F%2Fkasirenew.com%2Fquotation%2F${clientData.token}`;

  const sendLink = () => {
    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

  const preview = `https://kasirenew.com/quotation/${clientData.token}`;

  const previewLink = () => {
    // Open the WhatsApp link in a new tab
    window.open(preview, '_blank');
  };

  return (
    <div className="flex bg-gray-100 mb-14 md:mb-0">
      <Sidebar />
      <div className="flex-grow px-8 py-5">
        <Header />
        <div className="text-center">
          <h1 className="text-2xl font-semibold">SuccessSubmit</h1>
          <p className="text-gray-600">Welcome to the admin SuccessSubmit!</p>
          <div className="flex justify-center items-center m-4">
            <div className="text-green-500 text-6xl">
              <FaCheckCircle />
            </div>
          </div>
          <div className="flex justify-center">
            <Link to="/request" className="bg-purple-600 text-white px-4 py-2 m-2 rounded-md">Back to Request</Link>
            <button onClick={sendLink} className="bg-green-500 text-white px-4 py-2 m-2 rounded-md">Send Quotation Link</button>
            <button onClick={previewLink} className="bg-blue-500 text-white px-4 py-2 m-2 rounded-md">Preview Link</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessSubmit;
