import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ExtractQuotation = ({ setInfo }) => {
  // const [info, setInfo] = useState({
  //   model1: '',
  //   model2: '',
  //   model3: '',
  //   model4: '',
  //   sumCovered1: '',
  //   sumCovered2: '',
  //   sumCovered3: '',
  //   sumCovered4: '',
  //   basicContribution1: '',
  //   basicContribution2: '',
  //   basicContribution3: '',
  //   basicContribution4: '',
  //   // takafulType1: '',
  //   ncd1: '',
  //   ncd2: '',
  //   ncd3: '',
  //   ncd4: '',
  //   from1: '',
  //   from2: '',
  //   from3: '',
  //   from4: '',
  //   to1: '',
  //   to2: '',
  //   to3: '',
  //   to4: '',
  // });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const typedarray = new Uint8Array(e.target.result);
        const pdf = await pdfjs.getDocument(typedarray).promise;

        let fullText = '';

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map(item => item.str).join(' ');
          fullText += pageText + '\n';

          // Extract the required information using regex
          const name1 = fullText.match(/The Participant\s*:\s*([^\n]+?)\s*NRIC/);
          const name2 = fullText.match(/PARTICIPANT\s*:\s*([^\n]+?)\s*ADDRESS/);
          const name3 = fullText.match(/Name\s+([A-Z\s]+)\s*Address/);
          const name4 = fullText.match(/PARTICIPANT\s+PESERTA\s+([A-Z\s]+)\s*NRIC/);

          const nric1 = fullText.match(/NRIC\s*:\s*([\d-]+)/);
          const nric2 = fullText.match(/IC\s*\/\s*BUSINESS\s*REGIST\.?\s*NO\.\s*:\s*(\d{6}-\d{2}-\d{4})/);
          const nric3 = fullText.match(/IC\s\/\sBusiness\sPassport\s\/\sOther\sNumber\s+(\d{6}-\d{2}-\d{4})/);
          const nric4 = fullText.match(/NRIC\s+:\s+(\d{6}-\d{2}-\d{4})/);

          const plateNo1 = fullText.match(/No\. Pendaftaran\s*:\s*Registration No\s*:\s*([^\n]+?)\s*Sumbangan/);
          const plateNo2 = fullText.match(/REGIST\.?\s*NO\s*:\s*([A-Z0-9]+)/);
          const plateNo3 = fullText.match(/Registration\sNo\sVehicle\s+([A-Z0-9]+)/);
          const plateNo4 = fullText.match(/REGISTRATION NO\s+NO\. PENDAFTARAN\s+([A-Z0-9]+)/);

          const windscreen1 = fullText.match(/WINDSCREEN\s*\(\s*RM\s*([0-9,]+(?:\.[0-9]{2})?)\s*\)/);
          const windscreen2 = fullText.match(/BREAKAGE\s+OF\s+GLASS\s+IN\s+W\/SCREEN\s+(\d{1,3}(,\d{3})*\.\d{2})/);
          const windscreen3 = fullText.match(/WINDSCREEN\(RM\s([0-9,]+)\.\d{2}\)/);
          const windscreen4 = fullText.match(/W\/SCREEN\s+\(RM\s([0-9,]+)\.\d{2}\)/);

          const modelMatch1 = fullText.match(/Make & Type of body\s*:\s*([^\n]+?)\s*Keupayaan Padu/);
          const modelMatch2 = fullText.match(/MODEL\s*:\s*([^\n]+?)\s*CHASSIS NO/);
          const modelMatch3 = fullText.match(/Make & Model of Vehicle\s+([^\n]+)\s*Vehicle Body/);
          const modelMatch4 = fullText.match(/BUATAN & MODEL\s+([^\n]+)\s*CUBIC CAPACITY/);

          const sumCoveredMatch1 = fullText.match(/Sum Covered\s*:\s*(\d+,\d+.\d{2})/);
          const sumCoveredMatch2 = fullText.match(/SUM COVERED \(RM\)\s*:\s*([\d,\.]+)/);
          const sumCoveredMatch3 = fullText.match(/Sum Covered\s+([\d,\.]+)/);
          const sumCoveredMatch4 = fullText.match(/JUMLAH PERLINDUNGAN\s+RM\s+([\d,\.]+)/);

          const basicContributionMatch1 = fullText.match(/Total\sPayable\s:\s+(\d{4}\.\d{2})/);
          const basicContributionMatch2 = fullText.match(/TOTAL CONTRIBUTION\s*:\s*RM\s*([\d,\.]+)/);
          const basicContributionMatch3 = fullText.match(/Takaful Contribution Payable\s+([\d,\.]+)/);
          const basicContributionMatch4 = fullText.match(/JUMLAH PEMBAYARAN\s+([\d,\.]+)/);

          // const basicContributionMatch1 = fullText.match(/Basic Contribution\s*:\s*(\d+,\d+.\d{2})/);
          // const basicContributionMatch2 = fullText.match(/BASIC CONTRIBUTION\s*:\s*RM\s*([\d,\.]+)/);
          // const basicContributionMatch3 = fullText.match(/Basic Takaful Contribution\s+([\d,\.]+)/);
          // const basicContributionMatch4 = fullText.match(/SUMBANGAN ASAS\s+([\d,\.]+)/);

          // const takafulTypeMatch1 = fullText.match(/Class of Certificate\s*:\s*([^\n]+?)\s*Pada/);
          const ncdMatch1 = fullText.match(/NCD\s*\(?\s*(\d+\.\d{2})\)?/);
          const ncdMatch2 = fullText.match(/NCD\s*:\s*([\d\.]+)\s*%/);
          const ncdMatch3 = fullText.match(/-NCD\(([\d\.]+)%\)/);
          const ncdMatch4 = fullText.match(/NCD\s+([\d\.]+)%/);

          const fromMatch1 = fullText.match(/From\s+([\d\/]+)/);
          const toMatch1 = fullText.match(/To\s+([\d\/]+)/);
          const periodOfTakafulMatch = fullText.match(/PERIOD OF TAKAFUL\s*:\s*([\d\/]+)\s*TO\s*([\d\/]+)/);
          const effectiveDateMatch = fullText.match(/Effective Date\s+([\d\/]+)\s+to\s+([\d\/]+)/);
          const fromMatch2 = fullText.match(/a\) FROM\s+:\s+([\d-]+)/);
          const toMatch2 = fullText.match(/TO\s+:\s+([\d\/]+)/);

          const takafulTypeMatch = /COMPREHENSIVE/.test(fullText);

          console.log("Comprehensive found:", takafulTypeMatch);
          
          let takafulType = '';

          if (takafulTypeMatch) {
            takafulType = "Comprehensive";
          } else {
            takafulType = "3rd Party";
          }

          console.log("takafulType:", takafulType);

          setInfo({
            name1: name1 ? name1[1].trim() : '',
            name2: name2 ? name2[1].trim() : '',
            name3: name3 ? name3[1].trim() : '',
            name4: name4 ? name4[1].trim() : '',
            nric1: nric1 ? nric1[1].trim() : '',
            nric2: nric2 ? nric2[1].trim() : '',
            nric3: nric3 ? nric3[1].trim() : '',
            nric4: nric4 ? nric4[1].trim() : '',
            plateNo1: plateNo1 ? plateNo1[1].trim() : '',
            plateNo2: plateNo2 ? plateNo2[1].trim() : '',
            plateNo3: plateNo3 ? plateNo3[1].trim() : '',
            plateNo4: plateNo4 ? plateNo4[1].trim() : '',
            windscreen1: windscreen1 ? windscreen1[1].trim() : '',
            windscreen2: windscreen2 ? windscreen2[1].trim() : '',
            windscreen3: windscreen3 ? windscreen3[1].trim() : '',
            windscreen4: windscreen4 ? windscreen4[1].trim() : '',
            model1: modelMatch1 ? modelMatch1[1].trim() : '',
            model2: modelMatch2 ? modelMatch2[1].trim() : '',
            model3: modelMatch3 ? modelMatch3[1].trim() : '',
            model4: modelMatch4 ? modelMatch4[1].trim() : '',
            sumCovered1: sumCoveredMatch1 ? sumCoveredMatch1[1].trim() : '',
            sumCovered2: sumCoveredMatch2 ? sumCoveredMatch2[1].trim() : '',
            sumCovered3: sumCoveredMatch3 ? sumCoveredMatch3[1].trim() : '',
            sumCovered4: sumCoveredMatch4 ? sumCoveredMatch4[1].trim() : '',
            basicContribution1: basicContributionMatch1 ? basicContributionMatch1[1].trim() : '',
            basicContribution2: basicContributionMatch2 ? basicContributionMatch2[1].trim() : '',
            basicContribution3: basicContributionMatch3 ? basicContributionMatch3[1].trim() : '',
            basicContribution4: basicContributionMatch4 ? basicContributionMatch4[1].trim() : '',
            // takafulType1: takafulTypeMatch1 ? takafulTypeMatch1[1].trim() : '',
            ncd1: ncdMatch1 ? ncdMatch1[1].trim() : '',
            ncd2: ncdMatch2 ? ncdMatch2[1].trim() : '',
            ncd3: ncdMatch3 ? ncdMatch3[1].trim() : '',
            ncd4: ncdMatch4 ? ncdMatch4[1].trim() : '',
            from1: fromMatch1 ? fromMatch1[1].trim() : '',
            from2: periodOfTakafulMatch ? periodOfTakafulMatch[1].trim() : '',
            from3: effectiveDateMatch ? effectiveDateMatch[1].trim() : '',
            from4: fromMatch2 ? fromMatch2[1].trim() : '',
            to1: toMatch1 ? toMatch1[1].trim() : '',
            to2: periodOfTakafulMatch ? periodOfTakafulMatch[2].trim() : '',
            to3: effectiveDateMatch ? effectiveDateMatch[2].trim() : '',
            to4: toMatch2 ? toMatch2[1].trim() : '',
            takafulType: takafulType,
          });
        } 
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <h1>Upload a PDF file</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      {/* <div>
        <h2>Extracted Information</h2>
        <p>Takaful Type: {info.takafulType1}</p>
        <p>Model: {info.model1 || info.model2 || info.model3 || info.model4}</p>
        <p>Sum Covered: {info.sumCovered1 || info.sumCovered2 || info.sumCovered3 || info.sumCovered4}</p>
        <p>Basic Contribution: {info.basicContribution1 || info.basicContribution2 || info.basicContribution3 || info.basicContribution4}</p>
        <p>NCD: {info.ncd1 || info.ncd2 || info.ncd3 || info.ncd4}</p>
        <p>From: {info.from1 || info.from2 || info.from3 || info.from4}</p>
        <p>To: {info.to1 || info.to2 || info.to3 || info.to4}</p>
      </div> */}
    </div>
  );
};

export default ExtractQuotation;
