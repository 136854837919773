import React, { useState } from 'react';

import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import AddClientForm from '../../components/AddClientForm';
import Loading from '../../components/Loading';
import ExtractQuotation from '../../components/ExtractQuotation';
import SubmitQuotationForm from '../../components/SubmitQuotationForm';

function AddClient() {  
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({
    name1: '',
    name2: '',
    name3: '',
    name4: '',
    nric1: '',
    nric2: '',
    nric3: '',
    nric4: '',
    plateNo1: '',
    plateNo2: '',
    plateNo3: '',
    plateNo4: '',
    windscreen1: '',
    windscreen2: '',
    windscreen3: '',
    windscreen4: '',
    model1: '',
    model2: '',
    model3: '',
    model4: '',
    sumCovered1: '',
    sumCovered2: '',
    sumCovered3: '',
    sumCovered4: '',
    basicContribution1: '',
    basicContribution2: '',
    basicContribution3: '',
    basicContribution4: '',
    // takafulType1: '',
    ncd1: '',
    ncd2: '',
    ncd3: '',
    ncd4: '',
    from1: '',
    from2: '',
    from3: '',
    from4: '',
    to1: '',
    to2: '',
    to3: '',
    to4: '',
  });
  const [next, setNext] = useState(false);
  const [requestId, setRequestId] = useState('');
  const [plateNo, setPlateNo] = useState('');

  return (
    <div>
      {loading ? (
        <Loading />
      ):(
        <div className="flex bg-gray-100">
          <Sidebar />
          <div className="flex-grow px-8 py-5">
              <Header backTo={"/request"}/>
              <h1 className="text-2xl font-semibold">Add Client</h1>
              <ExtractQuotation setInfo={setInfo} />
              {!next ? (
                <AddClientForm setLoading={setLoading} setRequestId={setRequestId} setPlateNo={setPlateNo} setNext={setNext} info={info} />
              ) : (
                <SubmitQuotationForm setLoading={setLoading} requestId={requestId} plateNo={plateNo} info={info} />
              )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AddClient;
