

import React from 'react';

function SubmitNewForm() {
    return (
        <div className="py-4">

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* Left Column */}
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Vehicle Type:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Vehicle Type"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Name:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Name"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">NRIC:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter NRIC"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Plate No:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Plate No"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Brand:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Brand"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Model:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Model"
            />
            </div>

            {/* Right Column */}
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Variant:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Variant"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">NCD:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter NCD"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Market Value:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Market Value"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Sum Cover:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Sum Cover"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Price:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Price"
            />
            </div>
            <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Roadtax:</label>
            <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Roadtax"
            />
            </div>

            {/* Remaining fields in the right column */}
        </div>

        <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Windscreen Cover:</label>
            <input
            type="text"
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Windscreen Cover"
            />
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Effective Date:</label>
            <input
            type="text"
            className="border border-gray-300 rounded-lg p-2 w-full"
            placeholder="Enter Effective Date"
            />
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Takaful Options:</label>
            <select
            className="border border-gray-300 rounded-lg p-2 w-full"
            defaultValue=""
            >
            <option value="" disabled>Select Takaful Option</option>
            <option value="Takaful Ikhlas">Takaful Ikhlas</option>
            <option value="Takaful Malaysia">Takaful Malaysia</option>
            </select>
        </div>
        <div className="mb-4">
            <label className="block text-gray-700 font-semibold">Party Options:</label>
            <select
            className="border border-gray-300 rounded-lg p-2 w-full"
            defaultValue=""
            >
            <option value="" disabled>Select Party Option</option>
            <option value="Comprehensive">Comprehensive</option>
            <option value="3rd Party">3rd Party</option>
            <option value="Fire & Theft">Fire & Theft</option>
            </select>
        </div>

        <button className="bg-purple-600 text-white rounded-lg py-2 px-4 hover:bg-purple-500 mt-4">
            Submit
        </button>
        </div>
    );
}

export default SubmitNewForm
