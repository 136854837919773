import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaCar, FaCheckCircle, FaUsers, FaSignOutAlt, FaShoppingCart, FaCoins } from 'react-icons/fa';

const StickyNavbar = () => {

  const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  return (
    <div className="fixed bottom-0 left-0 w-full bg-purple-600 p-4 md:hidden">
      <ul className="flex justify-between">
        <li className="m-2 text-white hover:text-primary">
          <Link to="/">
            <FaHome />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/request">
            <FaCar />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/submitted">
            <FaCheckCircle />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/orders">
            <FaShoppingCart />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/pay">
            <FaCoins />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link to="/subagen">
            <FaUsers />
          </Link>
        </li>
        <li className="m-2 text-white hover:text-primary">
          <Link onClick={logout}>
            <FaSignOutAlt />
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default StickyNavbar;
