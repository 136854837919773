import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Set the root element for accessibility

const ConfirmationDialog = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Confirmation"
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
        content: {
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '25px',
          maxWidth: '400px',
          maxHeight: '250px',
          margin: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <h2 className="text-2xl font-semibold my-12 text-gray-800">Please confirm the transaction.</h2>
      <div className="flex justify-center space-x-4">
        <button
          onClick={() => {
            onConfirm();
            onClose();
          }}
          className="bg-purple-500 hover:bg-purple-600 text-white rounded-lg py-2 px-4 w-1/2"
        >
          Confirm
        </button>
        <button
          onClick={onClose}
          className="bg-red-500 hover:bg-red-600 text-white rounded-lg py-2 px-4 w-1/2"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationDialog;