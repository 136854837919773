import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import StickyNavbar from '../components/StickyNavbar';
import PaidOrders from '../components/PaidOrders';
import FailedOrders from '../components/FailedOrders';
import PendingOrders from '../components/PendingOrders';
import CompletedOrders from '../components/CompletedOrders'; // Import the CompletedOrders component

function Orders() {
  const [selectedTab, setSelectedTab] = useState('Pending');

  return (
    <div className="flex bg-gray-100 mb-14 md:mb-0 min-h-screen">
      <Sidebar />
      <div className="flex-grow px-5 md:px-8 py-5">
        <Header />
        <h1 className="text-2xl font-semibold">Orders</h1>
        <p className="text-gray-600">Welcome to the orders page!</p>

        <div className="mt-4 my-8">
          <button
            className={`${
              selectedTab === 'Paid' ? 'bg-purple-600 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedTab('Paid')}
          >
            Paid
          </button>
          <button
            className={`${
              selectedTab === 'Failed' ? 'bg-red-500 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedTab('Failed')}
          >
            Failed
          </button>
          <button
            className={`${
              selectedTab === 'Pending' ? 'bg-yellow-500 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedTab('Pending')}
          >
            Pending
          </button>
          <button
            className={`${
              selectedTab === 'Completed' ? 'bg-green-500 text-white' : 'bg-gray-200'
            } px-4 py-2 rounded`}
            onClick={() => setSelectedTab('Completed')}
          >
            Completed
          </button>
        </div>

        {selectedTab === 'Paid' && <PaidOrders />}
        {selectedTab === 'Failed' && <FailedOrders />}
        {selectedTab === 'Pending' && <PendingOrders />}
        {selectedTab === 'Completed' && <CompletedOrders />}
      </div>
      <StickyNavbar />
    </div>
  );
}

export default Orders;
