import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import axios from 'axios';
import StickyNavbar from '../components/StickyNavbar';

function Request() {
  const [newClients, setNewClients] = useState([]);
  const [repeatedClients, setRepeatedClients] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchTextRepeated, setSearchTextRepeated] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get('https://api.kasirenew.com/api/clientlist/without-quotation')
      .then((response) => {
        const newClientsData = response.data;
        console.log(newClientsData);
        setNewClients(newClientsData);
        setIsLoading(false);
      })
      .catch((quotationsError) => {
        console.error('An error occurred while fetching quotations data:', quotationsError);
        setIsLoading(false);
      });

    axios.get('https://api.kasirenew.com/api/clientlist/with-quotation')
      .then((response) => {
        const repeatedClientsData = response.data;
        console.log(repeatedClientsData);
        setRepeatedClients(repeatedClientsData);
        setIsLoading(false);
      })
      .catch((quotationsError) => {
        console.error('An error occurred while fetching quotations data:', quotationsError);
        setIsLoading(false);
      });
  }, []);

  const handleListItemClick = (id) => {
    navigate(`/request/${id}`);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const openWhatsApp = (whatsappNo, plateNo) => {
    const uppercasePlateNo = plateNo.toUpperCase(); // Convert plateNo to uppercase
    const whatsappLink = `https://wa.me/6${whatsappNo}?text=Assalammualaikum.%20Saya%20Jay%20dari%20KasiRenew.%0ASaya%20ada%20terima%20permohonan%20quotation%20insuran%20untuk%20kenderaan%20${uppercasePlateNo}.%0A%0ABoleh%20saya%20dapatkan%20gambar%20geran%20supaya%20saya%20dapat%20memberikan%20quotation%20yang%20tepat?%0A%0ATerima%20Kasih%20atas%20Kerjasama%20tn/pn.`;
    window.open(whatsappLink, '_blank');
  };

  // Filter new clients based on searchText
  const filteredNewClients = newClients.filter((client) =>
    client.plate_no.toLowerCase().includes(searchText.toLowerCase())
  );

  // Filter repeated clients based on searchText
  const filteredRepeatedClients = repeatedClients.filter((client) =>
    client.plate_no.toLowerCase().includes(searchText.toLowerCase())
  );

  const addClient = () => {
    navigate(`/addclient`);
  };

  return (
    <div className="flex bg-gray-100 min-h-screen mb-14 md:mb-0">
      <Sidebar />
      <div className="flex-grow px-8 py-5">
        <Header />
        <div className='flex justify-between'>
          <div>
            <h1 className="text-2xl font-semibold">Request</h1>
            <p className="text-gray-600">Welcome to the request page!</p>
          </div>
          <button onClick={addClient} className='px-5 py-3 rounded-lg bg-purple-500 hover:bg-purple-600 text-white'>Add Client</button>
        </div>

        {/* Search Form */}
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search Plate No..."
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={searchText}
            onChange={handleSearchInputChange}
          />
        </div>
        {isLoading ? (
          <div className="flex justify-center items-center mt-20">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-700"></div>
          </div>
        ) : (
          <>
            {filteredNewClients.length > 0 ? (
              <>
                <h2 className="text-lg font-semibold mt-6">New Clients</h2>
                <ul className="mt-2">
                  {filteredNewClients.map((item) => (
                    <li
                      className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-50 mb-4 cursor-pointer"
                    >
                      <div className="flex items-center justify-between">
                      <span className="font-semibold text-lg hidden sm:inline">{item.nama}</span>
                        <div className="flex items-center space-x-2">
                          <span className="text-gray-600 mr-10 uppercase">{item.plate_no}</span>
                          <button
                            key={item.id}
                            className="bg-purple-600 text-white rounded-md py-2 px-4 hover:bg-purple-700"
                            onClick={() => handleListItemClick(item.id)}
                          >
                            View
                          </button>
                          <button
                            className="bg-green-500 text-white rounded-md py-2 px-4 hover:bg-green-600"
                            onClick={() => openWhatsApp(item.whatsapp_no, item.plate_no)}
                          >
                            WhatsApp
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <>
                <h2 className="text-lg font-semibold mt-6">New Clients</h2>
                <p>No New Clients</p>
              </>
            )}

            {filteredRepeatedClients.length > 0 && (
              <>
                <h2 className="text-lg font-semibold mt-6">Repeated Clients</h2>
                <ul className="mt-2">
                  {filteredRepeatedClients.map((item) => (
                    <li
                      key={item.id}
                      className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-50 mb-4 cursor-pointer"
                      onClick={() => handleListItemClick(item.id)}
                    >
                      <div className="flex items-center justify-between">
                        <span className="font-semibold text-lg">{item.nama}</span>
                        <span className="text-gray-600 uppercase">{item.plate_no}</span>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </>
        )}
      </div>
      <StickyNavbar />
    </div>
  );
}

export default Request;
