// src/components/Sidebar.js

import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png'; // Import the logo image

function Sidebar() {
  const logout = () => {
    localStorage.removeItem('user');
    window.location.reload();
  };

  return (
    <div className="bg-purple-600 text-white h-screen md:block hidden sticky top-0 md:w-1/5">
     {/* Logo */}
      <div className="p-4">
        <img src={logo} alt="Logo" className="h-6 md:h-8" />
      </div>

      {/* Other sidebar content */}
      <ul>
        <li>
          <Link to="/" className="block p-4 hover:bg-purple-500">
            Dashboard
          </Link>
          <Link to="/request" className="block p-4 hover:bg-purple-500">
            Request
          </Link>
          <Link to="/submitted" className="block p-4 hover:bg-purple-500">
            Submitted
          </Link>
          <Link to="/orders" className="block p-4 hover:bg-purple-500">
            Orders
          </Link>
          <Link to="/pay" className="block p-4 hover:bg-purple-500">
            Pay
          </Link>
          <Link to="/subagen" className="block p-4 hover:bg-purple-500">
            SubAgen
          </Link>
          <Link className="block p-4 hover:bg-purple-500" onClick={logout}>
            Log Out
          </Link>
          {/* Add other navigation links */}
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
