import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function SubmitQuotationForm({ setLoading, requestId, plateNo, info }) {
  const [quotations, setQuotations] = useState([]);
  const navigate = useNavigate();
  const quotation = quotations[0];

  console.log("Client ID: ", requestId);
  console.log("Plate No: ", plateNo);

  // const [status, setStatus] = useState('');
  // console.log("Model:", info.model1 || info.model2 || info.model3 || info.model4);

  useEffect(() => {
    axios.get(`https://api.kasirenew.com/api/quotations?plateNo=${plateNo}`)
      .then((response) => {
        console.log(response.data);
        // setQuotations(response.data);
      })
      .catch((error) => {
        console.error('An error occurred while fetching quotation data:', error);
      });
  }, [requestId]);

  const formatDate = (dateStr) => {
    const [day, month, year] = dateStr.split(/[-\/]/);
    return `${year}-${month}-${day}`;
  };

  const removeCommas = (numberStr) => {
    return numberStr.replace(/,/g, '');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const updatedQuotationData = {
      plate_no: `${plateNo}`,
      client_id: `${requestId}`,
      brand: quotation.brand || info.model1 || info.model2 || info.model3 || info.model4,
      // model: quotation.model,
      // variant: quotation.variant,
      ncd: quotation.ncd || info.ncd1 || info.ncd2 || info.ncd3 || info.ncd4,
      market_value: quotation.market_value,
      sum_cover: quotation.sum_cover || removeCommas(info.sumCovered1 || info.sumCovered2 || info.sumCovered3 || info.sumCovered4),
      price: quotation.price || removeCommas(info.basicContribution1 || info.basicContribution2 || info.basicContribution3 || info.basicContribution4),
      roadtax: quotation.roadtax,
      windscreen: quotation.windscreen || removeCommas(info.windscreen1 || info.windscreen2 || info.windscreen3 || info.windscreen4),
      min_windscreen: "0",
      // min_windscreen: quotation.min_windscreen,
      special_perils: quotation.special_perils,
      towing: quotation.towing,
      motorpaplus: quotation.motorpaplus,
      legalliability: quotation.legalliability,
      from_date: quotation.from_date || formatDate(info.from1 || info.from2 || info.from3 || info.from4),
      to_date: quotation.to_date || formatDate(info.to1 || info.to2 || info.to3 || info.to4),
      // effective_date: quotation.effective_date,
      takaful_options: quotation.takaful_options,
      party_options: quotation.party_options || info.takafulType,
    };
    
    const updateStatus = {
      status: 'quotation_submitted',
    };

    try {

      // Update quotation data
      await axios.post(`https://api.kasirenew.com/api/quotations`, updatedQuotationData);
      await axios.put(`https://api.kasirenew.com/api/clients/${requestId}`, updateStatus);

      console.log(updatedQuotationData);

      // Redirect or show a success message
      navigate(`/request/${requestId}/success`); // Redirect to a success page
    } catch (error) {
      console.error('An error occurred while updating data:', error);
      // Handle errors or show an error message
    }
  };

  const handleQuotationChange = (field, value) => {
    const updatedQuotation = { ...quotation };
    updatedQuotation[field] = value;
    setQuotations([updatedQuotation]);
  };

  // const handleStatusChange = (event) => {
  //   setStatus(event.target.value);
  // };


  return (
    <div>
      <div className="py-4">
        <form onSubmit={handleFormSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Make & Model:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Make & Model"
                value={quotation?.brand || info.model1 || info.model2 || info.model3 || info.model4 || ''}
                onChange={(e) => handleQuotationChange('brand', e.target.value)}
                required
              />
            </div>
            {/* <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Model:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Model"
                value={quotation?.model || ''}
                onChange={(e) => handleQuotationChange('model', e.target.value)}
                required
              />
            </div> */}
            {/* Remaining fields in the first column */}
            {/* ... */}
            {/* Right Column */}
            {/* <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Variant:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Variant"
                value={quotation?.variant || ''}
                onChange={(e) => handleQuotationChange('variant', e.target.value)}
                required
              />
            </div> */}
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Sum Cover:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Sum Cover"
                value={quotation?.sum_cover || removeCommas(info.sumCovered1 || info.sumCovered2 || info.sumCovered3 || info.sumCovered4) || ''}
                onChange={(e) => handleQuotationChange('sum_cover', e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Price:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Price"
                value={quotation?.price || removeCommas(info.basicContribution1 || info.basicContribution2 || info.basicContribution3 || info.basicContribution4) || ''}
                onChange={(e) => handleQuotationChange('price', e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">NCD (%):</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter NCD"
                value={quotation?.ncd || info.ncd1 || info.ncd2 || info.ncd3 || info.ncd4 || ''}
                onChange={(e) => handleQuotationChange('ncd', e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Market Value:</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Market Value"
                onChange={(e) => handleQuotationChange('market_value', e.target.value)}
                required
              />
            </div>
            <div className="mb-4 flex">
              <div className="mr-2">
                <label className="block text-gray-700 font-semibold">From Date:</label>
                <input
                  type="date"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter From Date"
                  value={quotation?.from_date || formatDate(info.from1 || info.from2 || info.from3 || info.from4 || '')}
                  onChange={(e) => handleQuotationChange('from_date', e.target.value)}
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 font-semibold">To Date:</label>
                <input
                  type="date"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter To Date"
                  value={quotation?.to_date || formatDate(info.to1 || info.to2 || info.to3 || info.to4 || '')}
                  onChange={(e) => handleQuotationChange('to_date', e.target.value)}
                  required
                />
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Takaful Options:</label>
              <select
                className="border border-gray-300 rounded-lg p-2 w-full"
                onChange={(e) => handleQuotationChange('takaful_options', e.target.value)}
                required
              >
                <option value="">
                  Select Takaful Option
                </option>
                <option value="Takaful Ikhlas">Takaful Ikhlas</option>
                <option value="Takaful Malaysia">Takaful Malaysia</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Party Options:</label>
              <select
                className="border border-gray-300 rounded-lg p-2 w-full"
                onChange={(e) => handleQuotationChange('party_options', e.target.value)}
                value={quotation?.party_options || info.takafulType || ''}
                required
              >
                <option value="">
                  Select Party Option
                </option>
                <option value="Comprehensive">Comprehensive</option>
                <option value="3rd Party">3rd Party</option>
                <option value="3rd Party Fire & Theft">Fire & Theft</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Roadtax (tanpa upah):</label>
              <input
                type="text"
                className="border border-gray-300 rounded-lg p-2 w-full"
                placeholder="Enter Roadtax"
                onChange={(e) => handleQuotationChange('roadtax', e.target.value)}
                required
              />
            </div>
          </div>
          <div className='mt-8'>
            <h2 className='font-semibold text-xl'>Add-ons</h2>
            <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
              
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Windscreen Cover:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Windscreen Cover"
                  value={quotation?.windscreen || removeCommas(info.windscreen1 || info.windscreen2 || info.windscreen3 || info.windscreen4)}
                  onChange={(e) => handleQuotationChange('windscreen', e.target.value)}
                  
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Min. Windscreen Cover:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Minimum Windscreen Cover"
                  onChange={(e) => handleQuotationChange('min_windscreen', e.target.value)}
                  
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Special Perils:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Special Perils"
                  onChange={(e) => handleQuotationChange('special_perils', e.target.value)}
                  
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Towing:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Towing"
                  onChange={(e) => handleQuotationChange('towing', e.target.value)}
                  
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Motor PA Plus:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Motor PA Plus"
                  onChange={(e) => handleQuotationChange('motorpaplus', e.target.value)}
                  
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Legal Liability of Passanger:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Legal Liability of Passanger"
                  onChange={(e) => handleQuotationChange('legalliability', e.target.value)}
                  
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Status:</label>
                <select 
                  id="status" 
                  // value="quotation_submitted" 
                  onChange={handleStatusChange}
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  required
                >
                  <option value="">Select status</option>
                  <option value="quotation_submitted">New Submission</option>
                  <option value="paid">Paid</option>
                  <option value="completed">Completed</option>
                </select>
              </div> */}
            </div>
          </div>

          <button className="bg-purple-600 text-white rounded-lg py-2 px-4 hover:bg-purple-500 mt-4">
            Submit
          </button>
        </form>

      </div>
    </div>
  );
}

export default SubmitQuotationForm;