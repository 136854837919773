import React, { useEffect } from 'react';
import Sidebar from '../components/Sidebar'; // Import the Sidebar component
import Header from '../components/Header';
import StickyNavbar from '../components/StickyNavbar';
import Statistics from '../components/Statistics';
// import LineChart from '../components/LineChart';

function Dashboard() {
  return (
    <div className="flex bg-gray-100 mb-14 md:mb-0 min-h-screen">
      <Sidebar /> {/* Render the Sidebar component */}
      <div className="flex-grow px-8 py-5">
        <Header />
        {/* Dashboard content goes here */}
        <h1 className="text-2xl font-semibold">Dashboard</h1>
        <p className="text-gray-600">Welcome to the admin dashboard!</p>

        {/* <LineChart /> */}
        <Statistics />
      </div>
      <StickyNavbar />
    </div>
  );
}

export default Dashboard;
