import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import axios from "axios";
import StickyNavbar from "../components/StickyNavbar";

function Submitted() {
  const [allClients, setAllClients] = useState([]); // Store all clients
  const [clients, setClients] = useState([]); // Filtered clients
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Make a GET request to fetch data from the API
    axios
      .get("https://api.kasirenew.com/api/clients?status=quotation_submitted")
      .then((response) => {
        // Handle successful response and update the state
        setAllClients(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("An error occurred while fetching data:", error);
        setIsLoading(false);
      });
  }, []);

  // Filter clients based on search text
  useEffect(() => {
    const filteredClients = allClients.filter((client) =>
      client.plate_no.toLowerCase().includes(searchText.toLowerCase())
    );
    setClients(filteredClients);
  }, [searchText, allClients]);

  const handleListItemClick = (id, plate_no) => {
    setSelectedRequestId(id);
    navigate(`/submitted/${id}/${plate_no}/submitted`);
  };

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <div className="flex bg-gray-100 min-h-screen mb-14 md:mb-0">
      <Sidebar />
      <div className="flex-grow px-8 py-5">
        <Header />
        <div className="flex justify-between">
          <h1 className="text-2xl font-semibold">Submitted</h1>
        </div>
        <p className="text-gray-600">Welcome to the submitted page!</p>

        {/* Search Form */}
        <div className="mt-4">
          <input
            type="text"
            placeholder="Search Plate No..."
            className="border border-gray-300 rounded-lg p-2 w-full"
            value={searchText}
            onChange={handleSearchInputChange}
          />
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center mt-20">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-700"></div>
          </div>
        ) : (
          <ul className="mt-6">
            {clients.map((item) => (
              <li
                key={item.id}
                className="bg-white shadow-md rounded-lg p-4 hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 hover:bg-gray-50 mb-4 cursor-pointer"
                onClick={() => handleListItemClick(item.id, item.plate_no)}
              >
                <div className="flex items-center justify-between">
                  <span className="font-semibold text-lg">{item.nama}</span>
                  <span className="text-gray-600 uppercase">
                    {item.plate_no}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <StickyNavbar />
    </div>
  );
}

export default Submitted;
