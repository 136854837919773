import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from './Loading';

function ViewSubmittedForm({ requestId, plateNo, redirect }) {
  const [client, setClient] = useState(null);
  const [quotations, setQuotations] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const quotation = quotations[0];

  useEffect(() => {
    axios.get(`https://api.kasirenew.com/api/clients/${requestId}`)
      .then((response) => {
        setClient(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('An error occurred while fetching client data:', error);
      });

    axios.get(`https://api.kasirenew.com/api/getquotation/${requestId}`)
      .then((response) => {
        console.log(response.data);
        setQuotations(response.data);
      })
      .catch((error) => {
        console.error('An error occurred while fetching quotation data:', error);
      });
  }, [requestId]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Prepare updated data
    const updatedClientData = {
      vehicle_type: client.vehicle_type,
      nama: client.nama,
      id_number: client.id_number,
      plate_no: client.plate_no,
    };
    
    const updatedQuotationData = {
      plate_no: quotation.plate_no,
      brand: quotation.brand,
      // model: quotation.model,
      // variant: quotation.variant,
      ncd: quotation.ncd,
      market_value: quotation.market_value,
      sum_cover: quotation.sum_cover,
      price: quotation.price,
      roadtax: quotation.roadtax,
      windscreen: quotation.windscreen,
      min_windscreen: quotation.min_windscreen,
      special_perils: quotation.special_perils,
      towing: quotation.towing,
      from_date: quotation.from_date,
      to_date: quotation.to_date,
      takaful_options: quotation.takaful_options,
      party_options: quotation.party_options,
    };
    

    try {
      // Update client data
      await axios.put(`https://api.kasirenew.com/api/clients/${requestId}`, updatedClientData);

      // Update quotation data
      await axios.put(`https://api.kasirenew.com/api/quotations/${requestId}`, updatedQuotationData);

      console.log(updatedClientData);
      console.log(updatedQuotationData);
 
      // Redirect or show a success message
      navigate(`/submitted/${requestId}/success/${redirect}`); // Redirect to a success page
    } catch (error) {
      console.error('An error occurred while updating data:', error);
      // Handle errors or show an error message
    }
  };

  const handleInputChange = (field, value) => {
    setClient({ ...client, [field]: value });
  };

  const handleQuotationChange = (field, value) => {
    const updatedQuotation = { ...quotation };
    updatedQuotation[field] = value;
    setQuotations([updatedQuotation]);
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ):(
        <div className="py-4">
          <form onSubmit={handleFormSubmit}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Vehicle Type:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Vehicle Type"
                  value={client?.vehicle_type || ''}
                  onChange={(e) => handleInputChange('vehicle_type', e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Name:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Name"
                  value={client?.nama || ''}
                  onChange={(e) => handleInputChange('nama', e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">NRIC:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter NRIC"
                  value={client?.id_number || ''}
                  onChange={(e) => handleInputChange('id_number', e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Plate No:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full uppercase"
                  placeholder="Enter Plate No"
                  value={client?.plate_no || ''}
                  onChange={(e) => {
                    handleInputChange('plate_no', e.target.value);
                    handleQuotationChange('plate_no', e.target.value); // Call the quotation handler as well
                  }}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Make & Model:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Make & Model"
                  value={quotation?.brand || ''}
                  onChange={(e) => handleQuotationChange('brand', e.target.value)}
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Model:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Model"
                  value={quotation?.model || ''}
                  onChange={(e) => handleQuotationChange('model', e.target.value)}
                />
              </div> */}
              {/* Remaining fields in the first column */}
              {/* ... */}
              {/* Right Column */}
              {/* <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Variant:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Variant"
                  value={quotation?.variant || ''}
                  onChange={(e) => handleQuotationChange('variant', e.target.value)}
                />
              </div> */}
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Sum Cover:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Sum Cover"
                  value={quotation?.sum_cover || ''}
                  onChange={(e) => handleQuotationChange('sum_cover', e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Basic Contribution:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Price"
                  value={quotation?.price || ''}
                  onChange={(e) => handleQuotationChange('price', e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">NCD (%):</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter NCD"
                  value={quotation?.ncd || ''}
                  onChange={(e) => handleQuotationChange('ncd', e.target.value)}
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Market Value:</label>
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Market Value"
                  value={quotation?.market_value || ''}
                  onChange={(e) => handleQuotationChange('market_value', e.target.value)}
                />
              </div>
              <div className="mb-4 flex">
                <div className="mr-2">
                  <label className="block text-gray-700 font-semibold">From Date:</label>
                  <input
                    type="date"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter From Date"
                    value={quotation?.from_date || ''}
                    onChange={(e) => handleQuotationChange('from_date', e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700 font-semibold">To Date:</label>
                  <input
                    type="date"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter To Date"
                    value={quotation?.to_date || ''}
                    onChange={(e) => handleQuotationChange('to_date', e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Takaful Options:</label>
                <select
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={quotation?.takaful_options || ''}
                  onChange={(e) => handleQuotationChange('takaful_options', e.target.value)}
                >
                  <option value="" disabled>
                    Select Takaful Option
                  </option>
                  <option value="Takaful Ikhlas">Takaful Ikhlas</option>
                  <option value="Takaful Malaysia">Takaful Malaysia</option>
                </select>
              </div>
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold">Party Options:</label>
                <select
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  value={quotation?.party_options || ''}
                  onChange={(e) => handleQuotationChange('party_options', e.target.value)}
                >
                  <option value="" disabled>
                    Select Party Option
                  </option>
                  <option value="Comprehensive">Comprehensive</option>
                  <option value="3rd Party">3rd Party</option>
                  <option value="3rd Party Fire & Theft">Fire & Theft</option>
                </select>
              </div>
            </div>

            <div className='mt-8'>
              <h2 className='font-semibold text-xl'>Add-ons</h2>
              <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">Roadtax (tanpa upah):</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter Roadtax"
                    value={quotation?.roadtax || ''}
                    onChange={(e) => handleQuotationChange('roadtax', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">Windscreen Cover:</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter Windscreen Cover"
                    value={quotation?.windscreen || ''}
                    onChange={(e) => handleQuotationChange('windscreen', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">Min. Windscreen Cover:</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter Minimum Windscreen Cover"
                    value={quotation?.min_windscreen || ''}
                    onChange={(e) => handleQuotationChange('min_windscreen', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">Special Perils:</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter Special Perils"
                    value={quotation?.special_perils || ''}
                    onChange={(e) => handleQuotationChange('special_perils', e.target.value)}
                    required
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold">Towing:</label>
                  <input
                    type="text"
                    className="border border-gray-300 rounded-lg p-2 w-full"
                    placeholder="Enter Towing"
                    value={quotation?.towing || ''}
                    onChange={(e) => handleQuotationChange('towing', e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <button className="bg-purple-600 text-white rounded-lg py-2 px-4 hover:bg-purple-500 mt-4">
              Submit
            </button>
          </form>
        </div>
      )}
    </div>
  );
}

export default ViewSubmittedForm;
