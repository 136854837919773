import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Request from './pages/Request';
import SubmitQuotation from './pages/subpages/SubmitQuotation';
import SubmitNew from './pages/subpages/SubmitNew';
import ViewRequest from './pages/subpages/ViewRequest';
import Submitted from './pages/Submitted';
import ViewSubmitted from './pages/subpages/ViewSubmitted';
import Login from './pages/Login';
import Registration from './pages/Registration';
import Orders from './pages/Orders';
import Private from './components/Private';
import SuccessSubmit from './pages/subpages/SuccessSubmit';
import ViewInvoice from './pages/subpages/ViewInvoice';
import Pay from './pages/Pay';
import ViewDetails from './pages/subpages/ViewDetails';
import SubAgen from './pages/SubAgen';
import SubAgenInfo from './pages/subpages/SubAgenInfo';
import AddClient from './pages/subpages/AddClient';
import SuccessUpdate from './pages/subpages/SuccessUpdate';
// import ExtractQuotation from './pages/ExtractQuotation';
import UploadQuotation from './pages/UploadQuotation';

function App() {

  return (
    <Router>
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Registration />} /> */}

        {/* Private Routes */}
        <Route path="/" element={<Private Component={Dashboard} />} />
        {/* <Route path="/upload" element={<Private Component={ExtractQuotation} />} /> */}
        <Route path="/uploadquote" element={<Private Component={UploadQuotation} />} />
        <Route path="/request" element={<Private Component={Request} />} />
        <Route path="/addclient" element={<Private Component={AddClient} />} />
        <Route path="/request/:id" element={<Private Component={ViewRequest} />} />
        <Route path="/request/:id/:plate_no/submitquotation" element={<Private Component={SubmitQuotation} />} />
        <Route path="/request/:id/success" element={<Private Component={SuccessSubmit} />} />
        <Route path="/addnew" element={<Private Component={SubmitNew} />} />
        <Route path="/submitted" element={<Private Component={Submitted} />} />
        <Route path="/submitted/:id/:plate_no/:redirect" element={<Private Component={ViewSubmitted} />} />
        <Route path="/submitted/:id/success/:redirect" element={<Private Component={SuccessUpdate} />} />
        <Route path="/subagen" element={<Private Component={SubAgen} />} />
        <Route path="/subagen/:id" element={<Private Component={SubAgenInfo} />} />
        <Route path="/orders" element={<Private Component={Orders} />} />
        <Route path="/orders/view-invoice/:id" element={<Private Component={ViewInvoice} />} />
        <Route path="/pay" element={<Private Component={Pay} />} />
        <Route path="/pay/view-details/:user/:profit/:payout/:email" element={<Private Component={ViewDetails} />} />
      </Routes>
    </Router>
  );
}

export default App;
