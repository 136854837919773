// UploadQuotation.js
import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadQuotation = () => {
  const [file, setFile] = useState(null);
  const [info, setInfo] = useState({
    makeModel: '',
    sumCovered: '',
    basicContribution: '',
    ncd: '',
    startDate: '',
    endDate: ''
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const typedarray = new Uint8Array(e.target.result);
        const pdf = await pdfjs.getDocument(typedarray).promise;

        if (pdf.numPages >= 1) {
          const page = await pdf.getPage(1);
          const textContent = await page.getTextContent();
          const pageText = textContent.items.map(item => item.str).join(' ');

          // Extract the required information using regex
          const makeModelMatch = pageText.match(/BUATAN & MODEL\s+([^\n]+)\s*CUBIC CAPACITY/);
          const sumCoveredMatch = pageText.match(/JUMLAH PERLINDUNGAN\s+RM\s+([\d,\.]+)/);
          const basicContributionMatch = pageText.match(/SUMBANGAN ASAS\s+([\d,\.]+)/);
          const ncdMatch = pageText.match(/NCD\s+([\d\.]+)%/);
          const startDateMatch = pageText.match(/a\) FROM\s+:\s+([\d-]+)/);
          const endDateMatch = pageText.match(/TO\s+:\s+([\d\/]+)/);

          setInfo({
            makeModel: makeModelMatch ? makeModelMatch[1].trim() : '',
            sumCovered: sumCoveredMatch ? sumCoveredMatch[1].trim() : '',
            basicContribution: basicContributionMatch ? basicContributionMatch[1].trim() : '',
            ncd: ncdMatch ? ncdMatch[1].trim() : '',
            startDate: startDateMatch ? startDateMatch[1].trim() : '',
            endDate: endDateMatch ? endDateMatch[1].trim() : ''
          });
        } else {
          setInfo({
            makeModel: '',
            sumCovered: '',
            basicContribution: '',
            ncd: '',
            startDate: '',
            endDate: 'The PDF does not have a first page.'
          });
        }
      };
      reader.readAsArrayBuffer(file);
    }
  };

  return (
    <div>
      <h1>Upload a PDF file</h1>
      <input type="file" accept="application/pdf" onChange={handleFileChange} />
      {info.makeModel && (
        <div>
          <h2>Extracted Information</h2>
          <p>BUATAN & MODEL: {info.makeModel}</p>
          <p>JUMLAH PERLINDUNGAN: {info.sumCovered}</p>
          <p>SUMBANGAN ASAS: {info.basicContribution}</p>
          <p>NCD: {info.ncd}</p>
          <p>MULA: {info.startDate}</p>
          <p>TAMAT: {info.endDate}</p>
        </div>
      )}
    </div>
  );
};

export default UploadQuotation;
