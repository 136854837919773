import React, { useState, useEffect } from 'react';
import { FaCreditCard } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function PaidPay() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const fetchData = () => {
    // Fetch data from the API using Axios
    axios
      .get('https://api.kasirenew.com/api/paid-pay')
      .then((response) => {
        setData(response.data);
        console.log(response.data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const viewDetail = (user, profit, payout) => {
    navigate(`/pay/view-details/${user}/${profit}/${payout}`);
  };

  // Group data by payout_date
  const groupedData = data.reduce((result, pay) => {
    const payoutDate = new Date(pay.payout_date).toDateString();
    if (!result[payoutDate]) {
      result[payoutDate] = [];
    }
    result[payoutDate].push(pay);
    return result;
  }, {});

  return (
    <div>
      {data.length === 0 ? (
        <p className="text-gray-500">No data available.</p>
      ) : (
        <div>
          {Object.keys(groupedData).map((payoutDate) => (
            <div className="mb-12" key={payoutDate}>
              <h2 className="text-xl font-semibold mt-4 mb-2">Paid on: {payoutDate}</h2>
              <ul>
                {groupedData[payoutDate].map((pay) => (
                  <li key={pay.id} className="bg-white p-4 mb-4 rounded-lg shadow-md">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-lg font-semibold">{pay.user}</p>
                        <p className="text-gray-600">RM{pay.total_profit}</p>
                      </div>
                      {/* <div className="flex items-center">
                        <button
                          onClick={() => viewDetail(pay.user, pay.total_profit, pay.payout_date)}
                          className="bg-purple-600 text-white p-3 rounded-full flex items-center ml-2"
                        >
                          <FaCreditCard className="text-2xl" />
                        </button>
                      </div> */}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PaidPay;
