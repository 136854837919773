import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaFileInvoice } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function CompletedOrders() {
  const [data, setData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const today = new Date();
  const lastMonth = new Date();
  lastMonth.setMonth(today.getMonth() - 1);

  // Format the dates as YYYY-MM-DD
  const formatDate = (date) => {
    // return date.toISOString().split('T')[0];
    const malaysiaDate = new Date(date.toLocaleString('en-US', { timeZone: 'Asia/Kuala_Lumpur' }));
    return malaysiaDate.toISOString().split('T')[0];
  };

  // Initialize with default date range (last month to today)
  const [startDate, setStartDate] = useState(formatDate(lastMonth));
  const [endDate, setEndDate] = useState(formatDate(today));

  const navigate = useNavigate();

  const fetchData = () => {
    // Fetch data from the API using Axios
    axios
      .get('https://api.kasirenew.com/api/completed-orders')
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  };

  useEffect(() => {
    // Fetch data initially
    fetchData();
  }, []);
  

  const sendLink = (whatsapp_no) => {
    const whatsappLink = `https://wa.me/6${whatsapp_no}`;

    // Open the WhatsApp link in a new tab
    window.open(whatsappLink, '_blank');
  };

  const toggleDropdown = (itemId) => {
    setDropdownOpen((prev) => ({ ...prev, [itemId]: !prev[itemId] }));
  };

  const handleStatusChange = (status, id) => {
    // Make an API call to update the status
    axios.put(`https://api.kasirenew.com/api/update-status/${id}`, { status })
      .then((response) => {
        console.log(`Status updated to ${status}:`, response.data);
        fetchData();
      })
      .catch((error) => {
        console.error('Error updating status:', error);
      });
  };  

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  // const filteredData = data.filter(
  //   (order) =>
  //     order.client.plate_no.toLowerCase().includes(searchText.toLowerCase()) ||
  //     order.client.nama.toLowerCase().includes(searchText.toLowerCase())
  // );

  const filteredData = data.filter((order) => {
    // Check name or plate number
    const matchesText =
      order.client.plate_no.toLowerCase().includes(searchText.toLowerCase()) ||
      order.client.nama.toLowerCase().includes(searchText.toLowerCase());

    // Check date range
    const quotationDate = new Date(order.quotation.from_date);
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    const matchesDate =
      (!start || quotationDate >= start) && (!end || quotationDate <= end);

    // Return true if both conditions match
    return matchesText && matchesDate;
  });
  
  const viewInvoice = (orderId) => {
    navigate(`/orders/view-invoice/${orderId}`);
  };

  return (
    <div>
      <div className="mb-4 md:flex md:justify-items-center">
        <input
          type="text"
          placeholder="Search Plate No or Name"
          className="border border-gray-300 rounded-lg p-2 w-full"
          value={searchText}
          onChange={handleSearchInputChange}
        />
        <input
          type="date"
          className="border border-gray-300 rounded-lg p-2 w-1/2"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          className="border border-gray-300 rounded-lg p-2 w-1/2"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </div>
      {filteredData.length === 0 ? (
        <p className="text-gray-500">No data available.</p>
      ) : (
        <ul>
          {filteredData.map((order) => (
            <li key={order.client.id} className="bg-white p-4 mb-4 rounded-lg shadow-md">
              <div className="flex justify-between items-center">
              <div>
                  <p className="text-lg font-semibold">
                    {order.client.nama}
                  </p>
                  <p className="text-gray-600">
                    {order.client.plate_no}
                  </p>
                  <div className="text-sm">
                    {order.cart.price > 0 && (
                      <p>Takaful: RM{order.cart.price}</p>
                    )}
                    {order.cart.windscreen > 0 && (
                      <p>Windscreen (Cover: RM{order.cart.windscreen_cover}): RM{order.cart.windscreen}</p>
                    )}
                    {order.cart.special_perils > 0 && (
                      <p>Special Perils: RM{order.cart.special_perils}</p>
                    )}
                  </div>

                  <div className="mt-4 text-sm">
                    {order.cart.roadtax > 0 && (
                      <div>
                        <p>Roadtax: RM{order.cart.roadtax}</p>
                        {order.cart.roadtax_digital > 0 && (<p className="text-sm text-gray-700">+ Digital (RM{order.cart.roadtax_digital})</p>)}
                        {order.cart.roadtax_fizikal > 0 && (<p className="text-sm text-gray-700">+ Penghantaran/Pos (RM{order.cart.roadtax_fizikal})</p>)}
                      </div>
                    )}
                  </div>
                  <div className="font-semibold mt-6">
                      Total (Takaful + Add-ons): RM{(parseFloat(order.cart.price) + parseFloat(order.cart.windscreen) + parseFloat(order.cart.special_perils)).toFixed(2)}
                  </div>
                </div>
                <div className="flex items-center">
                  <button
                    onClick={() => sendLink(order.client.whatsapp_no)}
                    className="bg-green-500 text-white p-3 rounded-full flex items-center"
                  >
                    <FaWhatsapp className="text-2xl" />
                  </button>
                  <button
                    onClick={() => viewInvoice(order.client.id)}
                    className="bg-blue-500 text-white p-3 rounded-full flex items-center ml-2"
                  >
                    <FaFileInvoice className="text-2xl" />
                  </button>
                  <div className="relative inline-block text-left ml-2">
                    <button
                      onClick={() => toggleDropdown(order.client.id)}
                      className="bg-purple-600 text-white p-3 rounded-full flex items-center"
                    >
                      <span>&#9660;</span>
                    </button>
                    {dropdownOpen[order.client.id] && (
                      <div className="origin-top-right absolute right-0 mt-2 w-30 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <div className="py-1">
                          <button
                            onClick={() => {
                              handleStatusChange('pending', order.client.id);
                              toggleDropdown(order.client.id);
                            }}
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            Pending
                          </button>
                          <button
                            onClick={() => {
                              handleStatusChange('paid', order.client.id);
                              toggleDropdown(order.client.id);
                            }}
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            Paid
                          </button>
                          <button
                            onClick={() => {
                              handleStatusChange('failed', order.client.id);
                              toggleDropdown(order.client.id);
                            }}
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            Failed
                          </button>
                          <button
                            onClick={() => {
                              handleStatusChange('completed', order.client.id);
                              toggleDropdown(order.client.id);
                            }}
                            className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                          >
                            Completed
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CompletedOrders;
