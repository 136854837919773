import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RiCarLine, RiMotorbikeLine } from 'react-icons/ri';
import axios from "axios";

function AddClientForm({ setLoading, setRequestId, setPlateNo, setNext, info }) {
  const [selectedVehicle, setSelectedVehicle] = useState('car');
  const [formData, setFormData] = useState({
    vehicle_type: 'car',
    nama: '',
    id_type: '1',
    id_number: '',
    plate_no: '',
    email: '',
    whatsapp_no: '',
    status: 'new',
  });
  
  const handleVehicleChange = (event) => {
    setSelectedVehicle(event.target.value);
    setFormData({ ...formData, vehicle_type: event.target.value });
  };

  useEffect(() => {
    if (info.name1 || info.name2 || info.name3 || info.name4) {
      setFormData(prevData => ({
        ...prevData,
        nama: info.name1 || info.name2 || info.name3 || info.name4
      }));
    }
  }, [info.name1, info.name2, info.name3, info.name4]);

  useEffect(() => {
    if (info.nric1 || info.nric2 || info.nric3 || info.nric4) {
      setFormData(prevData => ({
        ...prevData,
        id_number: info.nric1 || info.nric2 || info.nric3 || info.nric4
      }));
    }
  }, [info.nric1, info.nric2, info.nric3, info.nric4]);

  useEffect(() => {
    if (info.plateNo1 || info.plateNo2 || info.plateNo3 || info.plateNo4) {
      setFormData(prevData => ({
        ...prevData,
        plate_no: info.plateNo1 || info.plateNo2 || info.plateNo3 || info.plateNo4
      }));
    }
  }, [info.plateNo1, info.plateNo2, info.plateNo3, info.plateNo4]);

  const navigate = useNavigate();

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
  
      const referralValue = 'direct';
  
      // Add 'referral' to the form data
      const updatedFormData = { ...formData, referral: referralValue };
  
      const response = await axios.post('https://api.kasirenew.com/api/clients', updatedFormData);
  
      if (response.status === 201) {
        // console.log(response);
        setRequestId(response.data.id);
        setPlateNo(response.data.plate_no);
        setNext(true);
        setLoading(false);
        // navigate(`/request/${response.data.id}/${response.data.plate_no}/submitquotation`);
      } else {
        console.error('Form submission failed.');
      }
    } catch (error) {
      console.error('An error occurred while submitting the form.', error);
    }
  };
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="py-8">
      <form
        onSubmit={handleFormSubmit}
        className="md:w-2/3"
      >
        <div className="mb-4">
          <div className="flex gap-4">
            <div className="flex items-center w-1/2">
              <input
                type="radio"
                id="car"
                name="vehicle_type"
                value="car"
                checked={selectedVehicle === "car"}
                onChange={handleVehicleChange}
                className="hidden"
              />
              <label
                htmlFor="car"
                className={`w-full px-4 py-2 rounded-md cursor-pointer select-none flex items-center transition-colors ease-in-out duration-300 ${
                  selectedVehicle === "car"
                    ? "bg-gray-500 text-white"
                    : "bg-white border border-gray-500 text-gray-500"
                }`}
              >
                <RiCarLine className="w-5 h-5 mr-2" /> Car
              </label>
            </div>
            <div className="flex items-center w-1/2">
              <input
                type="radio"
                id="motorcycle"
                name="vehicle_type"
                value="motorcycle"
                checked={selectedVehicle === "motorcycle"}
                onChange={handleVehicleChange}
                className="hidden"
              />
              <label
                htmlFor="motorcycle"
                className={`w-full px-4 py-2 rounded-md cursor-pointer select-none flex items-center transition-colors ease-in-out duration-300 ${
                  selectedVehicle === "motorcycle"
                    ? "bg-gray-500 text-white"
                    : "bg-white border border-gray-500 text-gray-500"
                }`}
              >
                <RiMotorbikeLine className="w-5 h-5 mr-2" /> Motorcycle
              </label>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="nama"
            className="block text-gray-800 font-semibold mb-2"
          >
            Nama
          </label>
          <input
            type="text"
            id="nama"
            name="nama"
            placeholder="Masukkan nama disini"
            className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
            required
            onChange={handleInputChange}
            value={formData.nama || info.name1 || info.name2 || info.name3 || info.name4}
          />
        </div>
        <div className="mb-4 flex gap-4">
          <div className="w-1/4">
            <label
              htmlFor="id_type"
              className="block text-gray-800 font-semibold mb-2"
            >
              ID Type
            </label>
            <select
              id="id_type"
              name="id_type"
              className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
              onChange={handleInputChange}
              value={formData.id_type}
            >
              <option value="1">NRIC</option>
              <option value="2">Passport</option>
              <option value="3">Army/Police</option>
            </select>
          </div>
          <div className="w-3/4">
            <label
              htmlFor="id_number"
              className="block text-gray-800 font-semibold mb-2"
            >
              ID Number
            </label>
            <input
              type="text"
              id="id_number"
              name="id_number"
              placeholder="XXXXXX-XX-XXXX"
              className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
              required
              onChange={handleInputChange}
              value={formData.id_number || info.nric1 || info.nric2 || info.nric3 || info.nric4}
            />
          </div>
        </div>
        <div className="mb-4">
          <label
            htmlFor="plate_no"
            className="block text-gray-800 font-semibold mb-2"
          >
            Plate No
          </label>
          <input
            type="text"
            id="plate_no"
            name="plate_no"
            placeholder="ABC1234"
            className="w-full p-3 rounded-md border border-gray-300 uppercase focus:outline-none focus:ring focus:ring-purple-500"
            required
            onChange={handleInputChange}
            value={formData.plate_no || info.plateNo1 || info.plateNo2 || info.plateNo3 || info.plateNo4}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="email"
            className="block text-gray-800 font-semibold mb-2"
          >
            Emel
          </label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Masukkan emel disini"
            className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
            required
            onChange={handleInputChange}
            value={formData.email}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="whatsapp_no"
            className="block text-gray-800 font-semibold mb-2"
          >
            No WhatsApp
          </label>
          <input
            type="text"
            id="whatsapp_no"
            name="whatsapp_no"
            placeholder="0123456789"
            className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:ring focus:ring-purple-500"
            required
            onChange={handleInputChange}
            value={formData.whatsapp_no}
          />
        </div>
        <div className="text-center">
          <button
            type="submit"
            className="w-full bg-purple-500 text-white p-3 rounded-md font-semibold hover:bg-purple-600 focus:outline-none focus:ring focus:ring-purple-500"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddClientForm;
