import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the id

import SubmitQuotationForm from '../../components/SubmitQuotationForm';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import Loading from '../../components/Loading';
import ExtractQuotation from '../../components/ExtractQuotation';

function SubmitQuotation() {
    const { id } = useParams(); // Get the ID parameter from the URL
    const { plate_no } = useParams();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({
      windscreen1: '',
      windscreen2: '',
      windscreen3: '',
      windscreen4: '',
      model1: '',
      model2: '',
      model3: '',
      model4: '',
      sumCovered1: '',
      sumCovered2: '',
      sumCovered3: '',
      sumCovered4: '',
      basicContribution1: '',
      basicContribution2: '',
      basicContribution3: '',
      basicContribution4: '',
      // takafulType1: '',
      ncd1: '',
      ncd2: '',
      ncd3: '',
      ncd4: '',
      from1: '',
      from2: '',
      from3: '',
      from4: '',
      to1: '',
      to2: '',
      to3: '',
      to4: '',
    });

    const back = `/request/${id}`;
  
    
  return (
    <div>
      {loading ? (
        <Loading />
      ):(
        <div className="flex bg-gray-100">
          <Sidebar />
          <div className="flex-grow px-8 py-5">
              <Header backTo={back}/>
              <h1 className="text-2xl font-semibold">Submit Quotation</h1>
              <p className="text-gray-600">Fill out the quotation form:</p>
              <ExtractQuotation setInfo={setInfo} />
              <SubmitQuotationForm setLoading={setLoading} requestId={id} plateNo={plate_no} info={info} />
          </div>
        </div>
      )}
    </div>
  );
}

export default SubmitQuotation;
