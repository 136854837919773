import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import axios from 'axios';

const Statistics = () => {
  const [selectedFilter, setSelectedFilter] = useState('today');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = (filter) => {
    axios
      .get(`https://api.kasirenew.com/api/statistics/${filter}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchData(selectedFilter);
  }, [selectedFilter]);

  return (
    <div className="bg-gray-100 mt-8">
      <div className="max-w-screen-xl mx-auto">
        {/* Filter Buttons */}
        <div className="mt-4 my-8">
          <button
            className={`${
              selectedFilter === 'today' ? 'bg-purple-600 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedFilter('today')}
          >
            Today
          </button>
          <button
            className={`${
              selectedFilter === 'yesterday' ? 'bg-purple-600 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedFilter('yesterday')}
          >
            Yesterday
          </button>
          <button
            className={`${
              selectedFilter === '7days' ? 'bg-purple-600 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedFilter('7days')}
          >
            7 Days
          </button>
          <button
            className={`${
              selectedFilter === '30days' ? 'bg-purple-600 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedFilter('30days')}
          >
            30 Days
          </button>
          <button
            className={`${
              selectedFilter === 'ayear' ? 'bg-purple-600 text-white' : 'bg-gray-200'
            } px-4 py-2 mr-2 rounded`}
            onClick={() => setSelectedFilter('ayear')}
          >
            A Year
          </button>
        </div>
        {loading ? (
          <div className="mt-12 flex items-center justify-center">
            <FaSpinner className="text-4xl text-purple-600 animate-spin" />
          </div>
        ) : (
          data && (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
              {/* Statistics Cards */}
              <div className="bg-white rounded-md shadow-md p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Quotation Requested</h2>
                <p className="text-2xl font-bold text-indigo-500">
                  {data.totalQuotationRequests}
                </p>
              </div>

              <div className="bg-white rounded-md shadow-md p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Sales</h2>
                <p className="text-2xl font-bold text-green-500">{data.totalSales}</p>
              </div>

              <div className="bg-white rounded-md shadow-md p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Total Profit</h2>
                <p className="text-2xl font-bold text-blue-500">RM{data.totalProfit}</p>
              </div>

              <div className="bg-white rounded-md shadow-md p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Total Sales Amount</h2>
                <p className="text-2xl font-bold text-purple-500">RM{data.total_sales_amount}</p>
              </div>
              <div className="bg-white rounded-md shadow-md p-6">
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Conversion Rate</h2>
                <p className="text-2xl font-bold text-red-500">{data.conversionRate}%</p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Statistics;