import React from 'react';
import { useParams } from 'react-router-dom'; // Import useParams to get the id

import ViewRequestForm from '../../components/ViewRequestForm';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';

function ViewRequest() {
    const { id } = useParams(); // Get the ID parameter from the URL
  
    
  return (
    <div>
      <div className="flex bg-gray-100 h-screen">
        <Sidebar />
        <div className="flex-grow px-8 py-5">
            <Header backTo={"/request"}/>
            <h1 className="text-2xl font-semibold">View Request</h1>
            <p className="text-gray-600">Fill out the quotation form:</p>
            <ViewRequestForm requestId={id} />
        </div>
      </div>
    </div>
  );
}

export default ViewRequest;
