import React, { useState, useEffect } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Sidebar from '../../components/Sidebar';
import Header from '../../components/Header';
import StickyNavbar from '../../components/StickyNavbar';
import ConfirmationDialog from '../../modals/ConfirmationDIalog';

function ViewDetails() {
  const navigate = useNavigate();
  const [detailsData, setDetailsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isCopied, setIsCopied] = useState([false, false, false, false]); // Initialize isCopied as an array
  const { user, profit, payout, email } = useParams();
  const [modalIsOpen, setModalIsOpen] = useState(false);


  const copyToClipboard = (text, index) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    // Update the isCopied state for the specified field
    const updatedIsCopied = [...isCopied];
    updatedIsCopied[index] = true;
    setIsCopied(updatedIsCopied);

    // Reset the 'Copied' text after a delay
    setTimeout(() => {
      updatedIsCopied[index] = false;
      setIsCopied(updatedIsCopied);
    }, 2000);
  };

  useEffect(() => {
    axios
      .get(`https://api.kasirenew.com/api/view-details/${user}`)
      .then((response) => {
        setDetailsData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [user]);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handlePay = () => {
    openModal();
  };

  const handleConfirm = () => {
    closeModal();
    setLoading(true);
    axios
      .put(`https://api.kasirenew.com/api/payout/${user}/${payout}`)
      .then((response) => {
        setLoading(false);
        // Handle the response here, if needed
        console.log(response.data); // Log the response data
        sendEmail();
        navigate('/pay');
      })
      .catch((error) => {
        // Handle errors, if any
        console.error('Error:', error);
      });
  };

  const sendEmail = async () => {
    try {
      const response = await axios.post(
        'https://api.brevo.com/v3/smtp/email',
        {
          sender: { name: 'KasiRenew', email: 'noreply@kasirenew.com' },
          to: [{email: email}, { email:'kasirenew@gmail.com' }],
          subject: "Komisen anda telah dibayar",
          templateId: 6,
          params: {
            name: detailsData.name,
            bank: detailsData.bank,
            accountno: detailsData.account,
            accountowner: detailsData.accountowner,
            profit: profit,
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'api-key': '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',
          },
        }
      );

      console.log('Email sent successfully:', response.data);
      
    } catch (error) {
      console.error('Error sending email:', error.response.data);
    }
  };

  const handleFailed = () => {
    
  };

  return (
    <div className="flex bg-gray-100 min-h-screen">
      <Sidebar />

      <div className="flex-grow px-3 md:px-8 py-5">
        <Header backTo="/pay" />
        <h1 className="text-3xl font-semibold mb-4">View Details</h1>
        <p className="text-gray-600 mb-8">Here's the details of your subagen/developer</p>

        {loading ? (
          <div className="mt-12 flex items-center justify-center">
            <FaSpinner className="text-4xl text-purple-600 animate-spin" />
          </div>
        ) : (
          <div className="py-4">
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Name:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Vehicle Type"
                  value={detailsData.name}
                  readOnly
                />
                {/* <button
                  onClick={() => copyToClipboard(detailsData.name, 0)}
                  className={`bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover:bg-purple-500 ${isCopied[0] ? 'bg-green-500' : ''}`}
                >
                  {isCopied[0] ? 'Copied' : 'Copy'}
                </button> */}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Bank:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Vehicle Type"
                  value={detailsData.bank}
                  readOnly
                />
                {/* <button
                  onClick={() => copyToClipboard(detailsData.bank, 1)}
                  className={`bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover:bg-purple-500 ${isCopied[1] ? 'bg-green-500' : ''}`}
                >
                  {isCopied[1] ? 'Copied' : 'Copy'}
                </button> */}
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Account No:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Vehicle Type"
                  value={detailsData.account}
                  readOnly
                />
                <button
                  onClick={() => copyToClipboard(detailsData.account, 2)}
                  className={`bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover-bg-purple-500 ${isCopied[2] ? 'bg-green-500' : ''}`}
                >
                  {isCopied[2] ? 'Copied' : 'Copy'}
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold">Account Owner:</label>
              <div className="flex items-center">
                <input
                  type="text"
                  className="border border-gray-300 rounded-lg p-2 w-full"
                  placeholder="Enter Vehicle Type"
                  value={detailsData.accountowner}
                  readOnly
                />
                {/* <button
                  onClick={() => copyToClipboard(detailsData.accountowner, 3)}
                  className={`bg-blue-500 text-white rounded-lg py-2 px-4 ml-2 hover-bg-purple-500 ${isCopied[3] ? 'bg-green-500' : ''}`}
                >
                  {isCopied[3] ? 'Copied' : 'Copy'}
                </button> */}
              </div>
            </div>
            <div className="md:my-12 mt-12 mb-20 flex items-center justify-between bg-white rounded-lg px-4 py-3 md:px-7 md:py-5">
              <h1 className="text-3xl font-semibold">RM{profit}</h1>
              <div className="space-x-2 md:space-x-4">
                <button
                  className="bg-white text-purple-600 border border-purple-600 rounded-lg py-2 px-4 hover:bg-purple-600 hover:text-white"
                  onClick={handlePay}
                >
                  Paid
                </button>
                <button
                  className="bg-red-500 text-white rounded-lg py-2 px-4 hover:bg-red-600 "
                  onClick={handleFailed}
                >
                  Failed
                </button>
              </div>
            </div>
            <ConfirmationDialog isOpen={modalIsOpen} onConfirm={handleConfirm} onClose={closeModal} />

          </div>
        )}
      </div>

      <StickyNavbar />
    </div>
  );
}

export default ViewDetails;
